import React, { useState, useEffect } from "react"
import Layout from '../../../../components/layouts'
import Banner from '../../../../components/common/Banner'
import { Link } from 'gatsby'
import SEO from '../../../../components/common/SEO'
import CountryPricing from '../../../../components/pricing/CountryPricing'
import Walkthrough from '../../../../images/svgs/Walkthrough'

function IndexEng() {

    const title = () => (
        <div>
            <span>What is</span>
            <br />Workhub?
        </div>
    )
    const bannerProps = {
        title: title,
        subtitle: "About Us",
        bannerImage: "/what-is-workhub/banner4.jpg",
        buttonLeft: {
            text: "Features",
            link: "/features/",
        },
        buttonRight: {
            text: "Pricing",
            link: "/pricing/",
        },
    }

    const seo = {
        title: "Workhub | What is Workhub? | Free EHS Management Software",
        description:
            "Workhub offers an all-in-one EHS Management Software with over 60+ training courses and tools to manage certificate tracking, policies, workplace screening, inspections, incident reporting, safety meetings and plenty more.",
    }


    return (
        <Layout>
            <SEO {...seo} />
            <Banner {...bannerProps} />

            <main className="container whatis-contain">

                {/* HOW DO WE HELP? */}

                <section className="black-swan align-child">

                    <div className="whatis-img">

                        <img src="/what-is-workhub/workers.png" alt="Workers Icon" />

                    </div>


                    <div className='whatis-text' style={{ marginLeft: '4rem' }}>

                        <h2>How do we help?</h2>

                        <p>Let’s be real, workplace safety and compliance can be challenging to manage because most workers cringe at the idea of having to do tedious administrative tasks just to follow “paper rules.”</p>


                        <p><strong>“Why bother,”</strong> they might think.</p>
                        <p><strong>“I’m always super-careful, nothing serious is ever likely to happen.”</strong></p>

                    </div>

                </section>


                {/* WHAT ABOUT THE BLACK SWAN? */}

                <section className='whatis-section black-swan switch-children'>

                    <div className='whatis-text'>

                        <h2>What about the Black Swan?</h2>

                        <p>At Workhub, we chose the <strong>black swan</strong> to be our logo for a reason.</p>

                        <p>The black swan theory is based on Nassim Talib’s description of events that are unexpected or improbable, in the same way that black swans are thought not to exist...until you actually see one.   </p>

                        <p>Most of us are conditioned to be complacent due to the extremely low probability of things going wrong, and we fail to understand that even though <strong>100% of accidents are unexpected,</strong> they are almost always foreseeable and preventable. That’s why at Workhub, <strong>we respect the improbable.</strong> We choose to push safety to the forefront of operations to ensure workers don’t discount the probability of workplace accidents.</p>

                    </div>

                    <div className="whatis-img">

                        <img src="/what-is-workhub/black-swan4.png" alt="Picture of elusive black swan saying surprise!" />

                    </div>

                </section>

                {/* OUR MISSION */}

                <section className='black-swan align-child whatis-section'>

                    <div className="whatis-img">

                        <img src="/what-is-workhub/subordinates.png" alt="Icon showing the interconnectivity of Workhub" />

                    </div>

                    <div className='whatis-text' style={{ marginLeft: '4rem' }}>

                        <h2>Our Mission:</h2>

                        <p>We want to help people see the value in day-to-day safety functions. In order to overcome the hesitancy around following safety protocols, we have created a purpose-built tool that makes safety compliance interactive and engaging. This helps build a <strong>culture of safety.  </strong></p>

                        <p>Moreover, we believe safety and compliance should be rewarded. As an organization, asking for compliance from your workers should warrant a thank you -  giving something back - which is why we have an exciting <strong>Recognition & Awards</strong> program to engage and motivate workers. That’s where your brand shines and where culture takes hold!</p>

                    </div>


                </section>


                </main>

                <Walkthrough/>

                <main className="container whatis-contain what-is-bottom">

                {/* WE TAKE PRIDE */}

                <section className='whatis-section'>

                    <h4>We take great pride in what we do and who we serve – we work hard to provide small- to medium-sized businesses with a <strong>comprehensive</strong> workplace compliance management system that is: </h4>


                    <div className='whatis-grid'>

                        <img src="/what-is-workhub/clock.png" alt="Clock Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>Quick and easy to use</strong> (improving workplaces within hours); with</p>
                        </div>

                        <img src="/what-is-workhub/support.png" alt="Support Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>Free implementation assistance</strong> (in case you need it); and</p>
                        </div>

                        <img src="/what-is-workhub/cost.png" alt="Hand with coins Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>Crazy low <Link to="/pricing/">pricing</Link></strong> - <CountryPricing/>
                                {/* - just $3/user per month; plus */}
                                </p></div>

                        <img src="/what-is-workhub/contract.png" alt="Contract Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>No contract</strong> - leave anytime, but you probably won’t!</p>
                        </div>


                    </div>

                    <p>We hope you enjoy your safety management experience through use of these <Link to="/features/">Features</Link> – feel free to explore our vast training library and over 25 customizable tools that will help you optimize your workflow, effectively communicate your health and safety programs to workers, and transform YOUR workplace culture!  </p>

                
                </section>

               


            </main>
        </Layout>
    )
}

export default IndexEng

