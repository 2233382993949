import React, {useContext} from 'react'
import {UserContext} from '../../components/layouts/UserContext'

function CountryPricing() {

    const location = useContext(UserContext)
    return (
      <>   
        {location === "US" || location === undefined ? "$3/user per month; plus" : null}
        {location === "CA" ? "$4/user per month; plus" : null}
        {location !== 'US' && location !== 'CA' && location !== undefined ? "$3/user per month; plus" : null}
       </> 
    )
}

export default CountryPricing
